import { ContentstackAllLandingPagesQuery as Query } from "../../../../../generated/graphql";
import { ImageAssetUrl } from "../../../assets/typings";
import ContentstackArticleClass from "../../../articles/class";
import { SectionPromoContentstackProps } from "../../../../../lib/sections/promo/transforms/contentstack";
import notEmpty from "../../../../utils/not-empty";

const modularBlockPromo = (
  section: Exclude<Query["all"]["edges"][0]["node"]["modular_blocks"][0]["promo_module"], null>,
) => {
  const promoItems: SectionPromoContentstackProps["promoItems"] = section.promo_module_selection
    .filter(notEmpty)
    .map((item) => {
      const entry = item.promo_reference[0];
      if (!entry) return null;
      if (!item.promo_full_width_banner) return null;

      let title;
      let url;
      let description;

      switch (entry.__typename) {
        case "Contentstack_articles":
          const article = new ContentstackArticleClass(entry);
          title = article.title;
          url = {
            url: article.url,
            internal: article.internal,
          };
          description = article.description;
          break;
      }

      const formattedItem: SectionPromoContentstackProps["promoItems"][0] = {
        url,
        category: item.subtitle ? item.subtitle : undefined,
        title: item.hide_title ? undefined : item.promo_title ? item.promo_title : title,
        description: !item.include_description ? undefined : item.description ? item.description : description,
        imageUrl: item.promo_full_width_banner.url as ImageAssetUrl,
      };

      return formattedItem;
    })
    .filter(notEmpty);

  if (promoItems.length <= 0) return null;

  return {
    promoItems,
  };
};

export default modularBlockPromo;
