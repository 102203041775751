import { ContentstackAllLandingPagesQuery as Query } from "../../../../../generated/graphql";
import contentstackSectionItemsToComponentSectionItems from "../contentstack-section-items-to-component-section-items";
import isInternalUrl from "../../../../utils/is-internal-url";
import { SectionNewsItemListContentstackProps } from "../../../../../lib/sections/news-item-list/transforms/contentstack";

const modularBlockFeaturedNews = (
  section: Exclude<Query["all"]["edges"][0]["node"]["modular_blocks"][0]["featured_news"], null>,
): SectionNewsItemListContentstackProps | null => {
  if (!section) return null;

  const items = contentstackSectionItemsToComponentSectionItems(section.items);

  // don't show section if empty
  if (items.length < 1) return null;

  return {
    title: section.label,
    viewMore:
      section.cta && section.cta.href
        ? {
            text: section.cta.title ? section.cta.title : section.cta.href,
            url: section.cta.href,
            internal: isInternalUrl(section.cta.href),
          }
        : undefined,
    items: items.slice(0, 3).map(({ text, description, ...itemProps }) => {
      return {
        headline: text,
        blurb: description,
        ...itemProps,
      };
    }),
  };
};

export default modularBlockFeaturedNews;
