import { ContentstackAllLandingPagesQuery as Query } from "../../../../../generated/graphql";
import { SectionNewsHighlightsListContentstackProps } from "../../../../../lib/sections/news/highlights-list/transforms/contentstack";
import contentstackSectionItemsToComponentSectionItems from "../contentstack-section-items-to-component-section-items";

const modularBlockFeaturedNews = (
  section: Exclude<Query["all"]["edges"][0]["node"]["modular_blocks"][0]["highlights"], null>,
): SectionNewsHighlightsListContentstackProps | null => {
  if (!section) return null;

  const items = contentstackSectionItemsToComponentSectionItems(section.items);

  // don't show section if empty
  if (items.length < 1) return null;

  return {
    sectionTitle: section.label,
    articles: items.slice(0, 3).map(({ uid, text, imageUrl, category, url }) => {
      return {
        id: uid,
        title: text,
        category,
        imageUrl,
        url,
      };
    }),
  };
};

export default modularBlockFeaturedNews;
