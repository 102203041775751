import { SectionType } from "../../../../lib/templates/category/typings";
import { TemplateContextData, ContentstackSection } from "../templates/default/typings";
import { ContentstackAllCategoriesQuery as Query } from "../../../../generated/graphql";
import {
  modularBlockFeaturedNews,
  modularBlockNewsHeader,
  modularBlockPromo,
} from "../../landing-pages/transforms/modular-blocks";
import { modularBlockFilteredNews, FilteredNewsArticleData } from "./modular-blocks";
import { ImageAssetUrl } from "../../assets/typings";
import { articleDataToArticle } from "./modular-blocks/filtered-news";
import notEmpty from "../../../utils/not-empty";

const transform = (node: Query["all"]["edges"][0]["node"]): TemplateContextData => {
  // transform sections
  const sections: ContentstackSection[] = node.modular_blocks
    .map((block) => blockToContentstackSection(node, block))
    .filter((section: ContentstackSection | null): section is ContentstackSection => section !== null);

  // EXCEPTION: Ensure Article List section is present even if one is not specified explicitly
  const hasArticleListSection = sections.some(({ type }) => type === SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK);
  if (!hasArticleListSection) {
    sections.push({
      type: SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK,
      props: {
        articles: node.articles.map((articleData) => articleDataToArticle(articleData)).filter(notEmpty),
        terms: [],
      },
    });
  }

  return {
    uid: node.uid,
    title: node.title,
    description: node.category_description,
    image: !node.meta_image
      ? null
      : {
          url: node.meta_image.url as ImageAssetUrl,
        },
    sections,
  };
};

export default transform;

const blockToContentstackSection = (
  node: Query["all"]["edges"][0]["node"],
  section: Query["all"]["edges"][0]["node"]["modular_blocks"][0],
): ContentstackSection | null => {
  const keys = Object.keys(section) as Array<keyof Query["all"]["edges"][0]["node"]["modular_blocks"][0]>;

  for (const key of keys) {
    if (!section[key]) continue;

    switch (key) {
      case "hero":
        return TRANSFORM_MAP[key](section[key]);
      case "featured_news":
        return TRANSFORM_MAP[key](section[key]);
      case "filtered_news":
        return TRANSFORM_MAP[key](section[key], {
          articles: node.articles,
        });
      case "promo_module":
        return TRANSFORM_MAP[key](section[key]);

      default:
        console.warn(`Section not integrated. (section: ${key})`);
    }
  }

  return null;
};

const TRANSFORM_MAP = {
  hero: (section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["hero"]): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockNewsHeader(section);
    if (!props) return null;

    return {
      type: SectionType.NEWS_HEADER,
      props,
    };
  },
  featured_news: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["featured_news"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockFeaturedNews(section);
    if (!props) return null;

    return {
      type: SectionType.NEWS_ITEM_LIST,
      props,
    };
  },
  filtered_news: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["filtered_news"],
    options: {
      articles: FilteredNewsArticleData[];
    },
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockFilteredNews(section, options);
    if (!props) return null;

    return {
      type: SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK,
      props,
    };
  },
  promo_module: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["promo_module"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockPromo(section);
    if (!props) return null;

    return {
      type: SectionType.PROMO,
      props,
    };
  },
};
