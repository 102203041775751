import { ContentstackAllLandingPagesQuery as Query } from "../../../../../generated/graphql";
import notEmpty from "../../../../utils/not-empty";
import contentstackNodeToVideoData from "../../../html5-videos/transforms/contentstack-node-to-video-data";
import { UrlFieldType } from "../../../../fields/url";

const modularBlockFooterVideo = (
  section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["footer_video"],
  options: {
    url: UrlFieldType;
  },
) => {
  if (!section) return null;

  const videos = section.footer_videos_entry
    .filter(notEmpty)
    .map((footerVideoEntry) => {
      const videoDatas = footerVideoEntry.video
        .filter(notEmpty)
        .map((entry) => contentstackNodeToVideoData(entry))
        .filter(notEmpty);

      if (videoDatas.length <= 0) return null;

      return videoDatas[0];
    })
    .filter(notEmpty);

  if (videos.length <= 0) return null;

  return {
    downloadUrl: options.url,
    videos,
  };
};

export default modularBlockFooterVideo;
