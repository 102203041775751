import { ContentstackArticleExcerptFragment, ContentstackAssets, Maybe } from "../../../../generated/graphql";
import { ImageAssetUrl } from "../../assets/typings";
import ContentstackArticleClass from "../../articles/class";
import { UrlFieldType } from "../../../fields/url";

export interface ComponentSectionItem {
  text: string;
  description: string;
  category?: string;
  url: UrlFieldType;
  uid: string;
  imageUrl: ImageAssetUrl;
}
export interface ContentstackSectionItem {
  tag_entries: Array<
    Maybe<{
      articles: Array<Maybe<ContentstackArticleExcerptFragment>>;
    }>
  >;
  content_entry: Array<
    Maybe<
      {
        __typename: "Contentstack_articles";
      } & ContentstackArticleExcerptFragment
    >
  >;
  image: Maybe<Pick<ContentstackAssets, "url" | "uid">>;
  title?: string | null;
  description?: string | null;
}

const contentstackSectionItemsToComponentSectionItems = (
  contentstacksSectionItems: ContentstackSectionItem[],
): ComponentSectionItem[] => {
  const sectionItems: ComponentSectionItem[] = [];

  itemLoop: for (const item of contentstacksSectionItems) {
    // articles from tags/categories
    const allArticles = item.tag_entries.reduce<ContentstackArticleClass[]>((articles, tagOrCatEntry) => {
      if (!tagOrCatEntry) return articles;

      for (const articleEntry of tagOrCatEntry.articles) {
        if (!articleEntry) continue;
        if (!articleEntry.banner) continue;
        articles.push(new ContentstackArticleClass(articleEntry));
      }

      return articles;
    }, []);

    // sort articles
    allArticles.sort((a, b) => b.publishDate.getTime() - a.publishDate.getTime());

    // add article as promo item, if it hasn't been used
    for (const article of allArticles) {
      if (sectionItems.some((sItem) => sItem.uid === article.uid)) continue;

      sectionItems.push({
        uid: article.uid,
        url: {
          url: article.url,
          internal: article.internal,
        },
        text: article.title,
        description: article.description,
        imageUrl: article.bannerUrl!,
        category: article.categoryTitle || "",
      });

      continue itemLoop;
    }

    // get specific article
    const entry = item.content_entry[0];
    if (!entry) continue;

    let sectionItem: ComponentSectionItem;

    switch (entry.__typename) {
      case "Contentstack_articles":
        const article = new ContentstackArticleClass(entry);

        sectionItem = {
          uid: article.uid,
          url: {
            url: article.url,
            internal: article.internal,
          },
          text: article.title,
          description: article.description,
          category: article.categoryTitle,
          imageUrl: article.bannerUrl!,
        };
        break;

      default:
        continue itemLoop;
    }

    if (item.image) {
      sectionItem.imageUrl = item.image.url as ImageAssetUrl;
    }
    if (item.title) {
      sectionItem.text = item.title;
    }
    if (item.description) {
      sectionItem.description = item.description;
    }

    sectionItems.push(sectionItem);
  }

  return sectionItems;
};

export default contentstackSectionItemsToComponentSectionItems;
