/**
 * Any internal link (intended for Gatsby)
 * will start with exactly one slash and no locale prefix,
 * anything else is considered external.
 * @param url
 */
export default function isInternalUrl(url: string): boolean {
  const isRelative = /^\/(?!\/)/.test(url);
  if (!isRelative) return false;

  const containsLocale = /^\/[a-z][a-z]-[a-z][a-z]/.test(url);
  if (containsLocale) return false;

  return true;
}
