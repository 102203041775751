import { VideoData } from "./typings";
import { ContentstackHtml5VideosVideo, ContentstackAssets, Maybe } from "../../../../generated/graphql";

interface Html5Video {
  video: Pick<ContentstackHtml5VideosVideo, "height" | "width"> & {
    file: Maybe<Pick<ContentstackAssets, "url" | "content_type">>;
  };
}

export default function contentstackNodeToVideoData({ video }: Html5Video): VideoData | null {
  if (!video.file) return null;
  return {
    sources: [
      {
        url: video.file.url,
        type: video.file.content_type,
        width: video.width || undefined,
        height: video.height || undefined,
      },
    ],
  };
}
