import { ContentstackAllLandingPagesQuery as Query } from "../../../../../generated/graphql";
import contentstackSectionItemsToComponentSectionItems, {
  ComponentSectionItem,
} from "../contentstack-section-items-to-component-section-items";

const modularBlockNewsHeader = (
  section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["hero"],
): ComponentSectionItem | null => {
  if (!section) return null;

  const items = contentstackSectionItemsToComponentSectionItems([section]);

  // don't show section if empty
  if (items.length < 1) return null;

  const item = items[0];

  return {
    ...item,
  };
};

export default modularBlockNewsHeader;
